import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "gatsby";
import classNames from "classnames/bind";
import styles from "./TagCard.module.scss";

const cx = classNames.bind(styles);

const TagCard = ({ url, value, totalCount, items }) => {
  return (
    <Card className={cx("tagCard")}>
      <Card.Body className={cx("cardFlex")}>
        <div>
          <Link to={url} className={cx("tagLink")}>
            {value}
          </Link>
          <Card.Text className={cx("relatedText")}>
            최근 포스팅
            <br />
            {items.slice(0, 3).map(({ node }) => {
              const to = node.fields.slug;
              return (
                <Link key={to} to={to}>
                  {node.frontmatter.title}
                  <br />
                </Link>
              );
            })}
          </Card.Text>
        </div>
        <Card.Subtitle className={cx("subTitle")}>
          {totalCount}개의 관련 글
        </Card.Subtitle>
      </Card.Body>
    </Card>
  );
};

export default TagCard;
